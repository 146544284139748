body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Code', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #EEEEEE;
}

* {
  box-sizing: border-box;
-moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
